import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  darkBlue,
  Subtitle,
  InnerWrapper,
  screen,
  mainWhite,
} from "../components/common/variables";

const Wrapper = styled.div`
  background: ${mainWhite};
  @media ${screen.large} {
    padding-top: 70px;
  }

  .inner-wrapper {
    padding: 40px 35px;
    @media ${screen.medium} {
      padding: 110px 35px;
    }
    @media ${screen.large} {
      padding: 90px 35px;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .content {
    p {
      color: ${darkBlue};
      font-size: 1rem;
      margin-bottom: 10px;
      @media ${screen.large} {
        font-size: 1.1rem;
        margin-bottom: 20px;
      }
    }
  }
`;
const Disclaimer = ({ data }) => {
  const content = data.content.data.disclaimer.html;
  return (
    <Layout>
      <SEO title="Disclaimer" />
      <Wrapper>
        <InnerWrapper className="inner-wrapper">
          <Subtitle className="title" dark>
            Disclaimer
          </Subtitle>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Disclaimer;

export const data = graphql`
  {
    content: prismicPrivacyAndDisclaimer {
      data {
        disclaimer {
          html
        }
      }
    }
  }
`;
